import React, { Component } from "react"
import ReactDOM from 'react-dom'
import Project from "../cards/project"

class ProjectGrid extends Component
{
  constructor() {
    super();
  }
  render() {
    return (
        <div className="row grid-list-a sr-grid">
          <Project/>
        </div>
      )
  }
}

export default ProjectGrid

