import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/master"
import SEO from "../components/seo"
import Project from "../components/cards/project"
import ProjectGrid from "../components/pages/project_grid";

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    {/*Home Header*/}
    <section id="home" className=" section-home  header-home ">

      <div className="section-content align-x-center align-y-center">
        <div className="width-medium">
          <div className="row no-gutters">
            <div className="col-12 col-lg-6">
              <div className="home-left content-text">
                <h2 className="h-title sr-up-2">
                  <span className="text-stroke">Hi</span>
                  <br/>There!
                </h2>

              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex min-h-full">
              <div className="home-right content-text pl-0">
                <div className="text sr-up-3">
                  <p>My name is Edwin. Here you will find some of the projects I've worked on, using Python, Javascript, PHP, Java and
                    Flutter</p>
                  <p>...oh and</p>
                  <h3>I'm looking for work :)</h3>
                </div>

                <div className="btns-group sr-up-4">
                  <a className="btn btn-line-a px-0 pb-0" href="#pages">
                    <span className="text">Scroll</span>
                    <span className="icon icon-menu icon-arrow-a icon-anim">
                      <span className="arrow-down"></span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*Begin Projects*/}
    <section className="section section-page section-margin bg-level-1">
      <div className="section-content align-x-center">
        <div className="c-wrapper width-medium">
          <ProjectGrid/>
        </div>
      </div>

    </section>
    {/*End Projects*/}
  </Layout>
)

export default IndexPage
