import React from "react"
import {Link} from "gatsby"

const Project = () => (
  <div className="item item-grid-a col col-12 col-md-6">
    <div className="item-img straight-border">
      <img className="img" src={require("../../assets/img/samples/img-person4.jpg")} alt="image"/>
      <div className="img-text mask-gradient-black">
        <h3 className="i-title font-title mb-0">Jack<br/>
          <span className="text-fill">Peterson</span></h3>
      </div>
    </div>
    <div className="item-text">
      <p>
        Co-founder of our startup, lead designer artist.
      </p>
      <Link className="btn btn-line-a px-0 py-0 my-2" to={'/project'}>
        <span className="text">Linkedin</span>
        <span className="icon icon-menu icon-arrow-a icon-anim">
                    <span className="arrow-right"></span>
                  </span>
      </Link>
    </div>
  </div>
)

export default Project;
